import React, { useMemo } from "react"
import "./popup.scss"
import { useStaticQuery, graphql } from "gatsby"
import { CircleArrow } from "../elements/svg-elements"
import { useLanguage } from "../../hooks/use-language"
import { useLocation } from "@gatsbyjs/reach-router"

type PopupProps = {
  locale: {
    locale: string
  }
  popupOption: {
    contentPopup: string
    urlPopup: string
    labelpopup: string
    showPopup: boolean
  }
}

const Popup: React.FC = () => {
  const { language } = useLanguage()
  const { pathname } = useLocation()
  const { POPUP, HOME } = useStaticQuery(graphql`
    query {
      POPUP: allWpPopup {
        nodes {
          locale {
            locale
          }
          popupOption {
            contentPopup
            urlPopup
            labelpopup
            showPopup
          }
        }
      }
      HOME: allWpPage(filter: { template: { templateName: { eq: "Home" } } }) {
        nodes {
          uri
        }
      }
    }
  `)

  const content = useMemo(() => {
    return POPUP.nodes.find((i: PopupProps) => i.locale.locale === language)
  }, [language, JSON.stringify(POPUP)])

  const homePaths = useMemo(() => {
    return HOME.nodes.map((h: { uri: string }) => h.uri)
  }, [JSON.stringify(HOME)])

  if (!homePaths.includes(pathname) || !content?.popupOption?.showPopup)
    return <></>

  return (
    <div className="popup">
      <div className="popup__wrapper container--xl">
        <h5>{content?.popupOption?.contentPopup}</h5>
        <a
          className="popup__link"
          href={content?.popupOption?.urlPopup}
          target="_blank"
        >
          <CircleArrow />
          <span>{content?.popupOption?.labelpopup}</span>
        </a>
      </div>
    </div>
  )
}

export default Popup
