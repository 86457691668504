export const pageTransitionDelay: number = 150;

export const defaultStyle = {
    transition: `opacity ${pageTransitionDelay}ms ease-in-out`,
    opacity: 0,
};

export const transitionStyles = {
    entering:   { opacity: 1, transition: 'all 1s cubic-bezier(0,1,.46,.98)' },
    entered:    { opacity: 1, transition: 'all 1s cubic-bezier(0,1,.46,.98)' },
    exiting:    { opacity: 0, transition: 'all 1s cubic-bezier(0,1,.46,.98)' },
    exited:     { opacity: 0, transition: 'all 1s cubic-bezier(0,1,.46,.98)' },
};

export const isBrowser = () => typeof window !== "undefined";
export const isDocument = () => typeof document !== "undefined";
