import React from "react"
import "./footer.scss"
import { Logo } from "../elements/svg-elements"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useLanguage } from "../../hooks/use-language"
import { scrollToTop } from "../../utils/helpers"
import Disclaimer from "./disclaimer"

type Page = {
  uri: string
  title: string
  locale: {
    locale: string
  }
}

type Group = {
  nodes: Page[]
}

const Footer: React.FC = () => {
  const { language } = useLanguage()
  const { CONTACT, IMPRESSUM, PRIVACY_POLICY, SOCIAL_LINKS } =
    useStaticQuery(graphql`
      query {
        CONTACT: allWpPage(
          filter: { template: { templateName: { eq: "Contact" } } }
        ) {
          nodes {
            uri
            title
            locale {
              locale
            }
          }
        }
        IMPRESSUM: allWpPage(
          filter: { template: { templateName: { eq: "Impressum" } } }
        ) {
          nodes {
            uri
            title
            locale {
              locale
            }
          }
        }
        PRIVACY_POLICY: allWpPage(
          filter: { template: { templateName: { eq: "Privacy-policy" } } }
        ) {
          nodes {
            uri
            title
            locale {
              locale
            }
          }
        }
        SOCIAL_LINKS: allWp {
          nodes {
            footerOptionsSettings {
              footerOptions {
                socialMedia {
                  linkedin
                }
              }
            }
          }
        }
      }
    `)

  const socialLinks =
    SOCIAL_LINKS?.nodes[0]?.footerOptionsSettings?.footerOptions?.socialMedia

  function footerPages() {
    const groupPages = [CONTACT, IMPRESSUM, PRIVACY_POLICY]
    const filteredPages = groupPages.map((array: Group) => {
      return array.nodes.filter((item: Page) => item.locale.locale === language)
    })
    return filteredPages.flat()
  }

  return (
    <footer className="footer">
      <div className="footer__wrapper container--xl">
        <div className="footer__elements-container">
          <Logo
            className="footer__elements-container__logo"
            onClick={scrollToTop}
          />
          <ul className="footer__elements-container__items">
            {footerPages() &&
              footerPages().map((item: Page, index: number) => (
                <li key={index}>
                  <Link to={item.uri}>{item.title}</Link>
                </li>
              ))}
          </ul>
        </div>
        <Disclaimer className="disclaimer--footer" />
      </div>
    </footer>
  )
}

export default Footer
