import React, { memo, useEffect } from "react"
import "./nav-item-mobile.scss"
import { NavItemProps } from "../../types/components"
import { useLocation } from "@gatsbyjs/reach-router"
import { Link } from "gatsby"
import { CircleArrow, DropdownIcon } from "../elements/svg-elements"

const NavItemMobile: React.FC<NavItemProps> = ({
  item,
  navToggle,
  linksArray,
  itemId,
  toggleNavItem,
  index,
}) => {
  const { pathname } = useLocation()

  function hasActiveChild(childrenNodes: any) {
    const activeChildren = childrenNodes.find((ch: any) => ch.uri === pathname)
    return activeChildren?.uri === pathname
  }

  useEffect(() => {
    if (!navToggle) {
      toggleNavItem(null)
    }
  }, [navToggle])

  return (
    <li
      className={
        hasActiveChild(item.children) ||
        item.path === pathname ||
        itemId === index ||
        !linksArray?.includes(pathname)
          ? `nav-item-mobile`
          : `nav-item-mobile faded`
      }
      onClick={() => toggleNavItem(index)}
    >
      {item.children.length === 0 ? (
        <Link
          to={item.path}
          getProps={({
            isPartiallyCurrent,
          }: {
            isPartiallyCurrent: boolean
          }) => {
            return isPartiallyCurrent
              ? { className: `nav-item-mobile__link active` }
              : { className: `nav-item-mobile__link` }
          }}
        >
          <CircleArrow />
          {item.title}
        </Link>
      ) : (
        <span
          className={
            hasActiveChild(item.children) || itemId === index
              ? "nav-item-mobile__link active"
              : "nav-item-mobile__link"
          }
        >
          <CircleArrow />
          {item.title}
          <div className="nav-item-mobile__link__description">
            <DropdownIcon />
          </div>
        </span>
      )}
      {(itemId === index || hasActiveChild(item.children)) && (
        <span className="nav-item-mobile__dropdown-hook">
          <ul className="nav-item-mobile__dropdown__body">
            {item.children?.map((link: any, index: number) => (
              <li key={index} className="nav-item-mobile__dropdown__body__item">
                <Link
                  to={link.uri}
                  getProps={({
                    isPartiallyCurrent,
                  }: {
                    isPartiallyCurrent: boolean
                  }) => {
                    return isPartiallyCurrent
                      ? {
                          className: `nav-item-mobile__dropdown__body__item__link active`,
                        }
                      : {
                          className: `nav-item-mobile__dropdown__body__item__link`,
                        }
                  }}
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </span>
      )}
    </li>
  )
}

export default memo(NavItemMobile)
