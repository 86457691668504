/*
    SEO
*/
export const seoUtils = (data: {[key: string]: any}) => {
    return {
        title: data?.title,
        lang: 'en',
        meta: [
            {
                name: `description`,
                content: data?.metaDesc,
            },
            {
                property: `og:title`,
                content: data?.opengraphTitle,
            },
            {
                property: `og:description`,
                content: data?.opengraphDescription,
            },
            {
                property: `og:type`,
                content: data?.opengraphType,
            },
            {
                name: `twitter:title`,
                content: data?.twitterTitle,
            },
            {
                name: `twitter:description`,
                content: data?.twitterDescription,
            },
        ]
    }
};

/*
    Locales
*/
export const locales = [
    {
        lang: 'Deutsch',
        code: 'de_DE',
        prefix: 'de'
    },
    {
        lang: 'English',
        code: 'en_US',
        prefix: 'en'
    },
    {
        lang: 'Español',
        code: 'es_ES',
        prefix: 'es'
    },
    {
        lang: 'Français',
        code: 'fr_FR',
        prefix: 'fr'
    }
];
