import React, { memo, useMemo } from "react"
import "./locales.scss"
import { locales } from "../../utils/containers"
import { Globe } from "../elements/svg-elements"
import { useLanguage } from "../../hooks/use-language"
import { useLocation } from "@gatsbyjs/reach-router"
import { navigate, useStaticQuery, graphql } from "gatsby"

type Locale = {
  lang: string
  code: string
  prefix: string
}

interface Props {
  itemId: number | null
  toggleNavItem: (index: number | null) => void
  linksArray: string[]
}

interface Props {
  itemId: number | null
  toggleNavItem: (index: number | null) => void
}

export type Page = {
  uri: string
  locale: { locale: string }
  template?: { templateName: string }
  singleTeam?: { teamIdentifier: string }
  singleCasesOptions?: { caseIdentifier: string }
  singleArticleOptions?: { articleIdentifier: string }
}

const Locales: React.FC<Props> = ({ itemId, toggleNavItem, linksArray }) => {
  const { language } = useLanguage()
  const { pathname } = useLocation()
  const { PAGES, TEAM, CASES, ARTICLES } = useStaticQuery(graphql`
    query {
      PAGES: allWpPage {
        nodes {
          uri
          template {
            templateName
          }
          locale {
            locale
          }
        }
      }
      TEAM: allWpTeam {
        nodes {
          uri
          singleTeam {
            teamIdentifier
          }
          locale {
            locale
          }
        }
      }
      CASES: allWpCase {
        nodes {
          uri
          singleCasesOptions {
            caseIdentifier
          }
          locale {
            locale
          }
        }
      }
      ARTICLES: allWpArticle {
        nodes {
          uri
          singleArticleOptions {
            articleIdentifier
          }
          locale {
            locale
          }
        }
      }
    }
  `)

  const index: 100 = 100

  const targetArr = useMemo(() => {
    let allPages = [PAGES, TEAM, CASES, ARTICLES].find((group: any) =>
      group?.nodes?.find((page: any) => page.uri === pathname)
    )?.nodes
    if (allPages) return allPages
    return []
  }, [])

  if (targetArr.length < 1) return <></>

  function findPage(targetLocale: string) {
    const targetArr = [PAGES, TEAM, CASES, ARTICLES].find((group: any) =>
      group?.nodes?.find((page: any) => page.uri === pathname)
    ).nodes
    const targetObj = targetArr.find((page: any) => page.uri === pathname)
    if ("template" in targetObj) {
      return targetArr
        .filter(
          (page: Page) =>
            page.template?.templateName === targetObj.template?.templateName
        )
        .find((page: Page) => page.locale.locale === targetLocale)
    } else if ("singleTeam" in targetObj) {
      return targetArr
        .filter(
          (page: Page) =>
            page.singleTeam?.teamIdentifier ===
            targetObj.singleTeam?.teamIdentifier
        )
        .find((page: Page) => page.locale.locale === targetLocale)
    } else if ("singleCasesOptions" in targetObj) {
      return targetArr
        .filter(
          (page: Page) =>
            page.singleCasesOptions?.caseIdentifier ===
            targetObj.singleCasesOptions?.caseIdentifier
        )
        .find((page: Page) => page.locale.locale === targetLocale)
    } else {
      return targetArr
        .filter(
          (page: Page) =>
            page.singleArticleOptions?.articleIdentifier ===
            targetObj.singleArticleOptions?.articleIdentifier
        )
        .find((page: Page) => page.locale.locale === targetLocale)
    }
  }

  function changeLanguage(locale: Locale) {
    if (locale.code === language) return
    const page = findPage(locale.code)
    if (page) {
      toggleNavItem(null)
      return navigate(page.uri)
    } else {
      toggleNavItem(null)
      console.log("Page not found")
      return navigate(`/`)
    }
  }

  return (
    <li
      className={
        itemId === index || !linksArray?.includes(pathname)
          ? "locales"
          : "locales faded"
      }
      onMouseEnter={() => toggleNavItem(index)}
      onMouseLeave={() => toggleNavItem(null)}
    >
      <span className="locales__icon">
        <Globe />
      </span>
      <div className="locales__dropdown-handler">
        {itemId === index && (
          <ul className="locales__dropdown">
            {locales.map((locale: Locale, index: number) => (
              <li
                key={index}
                onClick={() => changeLanguage(locale)}
                className={locale.code === language ? "active" : ""}
              >
                {locale.lang}
              </li>
            ))}
          </ul>
        )}
      </div>
    </li>
  )
}

export default memo(Locales)
