exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-not-found-tsx": () => import("./../../../src/pages/not-found.tsx" /* webpackChunkName: "component---src-pages-not-found-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-ausbildung-tsx": () => import("./../../../src/templates/ausbildung.tsx" /* webpackChunkName: "component---src-templates-ausbildung-tsx" */),
  "component---src-templates-cases-tsx": () => import("./../../../src/templates/cases.tsx" /* webpackChunkName: "component---src-templates-cases-tsx" */),
  "component---src-templates-coaching-tsx": () => import("./../../../src/templates/coaching.tsx" /* webpackChunkName: "component---src-templates-coaching-tsx" */),
  "component---src-templates-como-academy-tsx": () => import("./../../../src/templates/como-academy.tsx" /* webpackChunkName: "component---src-templates-como-academy-tsx" */),
  "component---src-templates-como-moves-tsx": () => import("./../../../src/templates/como-moves.tsx" /* webpackChunkName: "component---src-templates-como-moves-tsx" */),
  "component---src-templates-consulting-tsx": () => import("./../../../src/templates/consulting.tsx" /* webpackChunkName: "component---src-templates-consulting-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-impressum-tsx": () => import("./../../../src/templates/impressum.tsx" /* webpackChunkName: "component---src-templates-impressum-tsx" */),
  "component---src-templates-philosophy-tsx": () => import("./../../../src/templates/philosophy.tsx" /* webpackChunkName: "component---src-templates-philosophy-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-project-implementation-tsx": () => import("./../../../src/templates/project-implementation.tsx" /* webpackChunkName: "component---src-templates-project-implementation-tsx" */),
  "component---src-templates-responsibilities-tsx": () => import("./../../../src/templates/responsibilities.tsx" /* webpackChunkName: "component---src-templates-responsibilities-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-single-article-tsx": () => import("./../../../src/templates/single-article.tsx" /* webpackChunkName: "component---src-templates-single-article-tsx" */),
  "component---src-templates-single-case-tsx": () => import("./../../../src/templates/single-case.tsx" /* webpackChunkName: "component---src-templates-single-case-tsx" */),
  "component---src-templates-team-member-tsx": () => import("./../../../src/templates/team-member.tsx" /* webpackChunkName: "component---src-templates-team-member-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */)
}

