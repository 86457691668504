import {
  HeaderStateProps,
  HeaderActionProps,
  HeaderActionType,
  MapStateProps,
  MapActionProps,
  MapActionType,
} from "./../types/components"

//Navbar Reducer
export type HeaderReducer<HeaderStateProps, HeaderActionProps> = (
  state: HeaderStateProps,
  action: HeaderActionProps
) => any

export const headerReducer: HeaderReducer<
  HeaderStateProps,
  HeaderActionProps
> = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case HeaderActionType.SET_Y_POSITION:
      return {
        ...state,
        y: payload,
      }
    case HeaderActionType.SET_SCROLL:
      return {
        ...state,
        scroll: payload,
      }
    case HeaderActionType.SET_NAVBAR:
      return {
        ...state,
        navbar: payload,
      }
    case HeaderActionType.SET_NAV_ITEM:
      return {
        ...state,
        itemId: payload,
      }
    case HeaderActionType.SET_NAVLINKS:
      return {
        ...state,
        navLinks: payload,
      }
    case HeaderActionType.SET_MULTIPLE:
      return {
        ...state,
        ...payload,
      }
    default:
      break
  }
}

//Google Map Reducer
export type MapReducer<MapStateProps, MapActionProps> = (
  state: MapStateProps,
  action: MapActionProps
) => MapStateProps

export const mapReducer: MapReducer<MapStateProps, MapActionProps> = (
  state,
  action
) => {
  const { type, payload } = action
  switch (type) {
    case MapActionType.SET_MAP_CENTER:
      return {
        ...state,
        center: payload,
      }
    case MapActionType.SET_MAP_SETTINGS:
      return {
        ...state,
        map: payload,
      }
    case MapActionType.SET_INFO_WINDOW:
      return {
        ...state,
        infoWindow: payload,
      }
    case MapActionType.SET_MULTIPLE:
      return {
        ...state,
        ...payload,
      }
    default:
      throw new Error("action.type provided does not exist!")
  }
}
