import React, { memo, useEffect } from "react"
import "./nav-item-desktop.scss"
import { NavItemProps } from "../../types/components"
import { useLocation } from "@gatsbyjs/reach-router"
import { Link } from "gatsby"
import { CircleArrow, DropdownIcon } from "../elements/svg-elements"

const NavItemDesktop: React.FC<NavItemProps> = ({
  item,
  navToggle,
  itemId,
  toggleNavItem,
  index,
}) => {
  const { pathname } = useLocation()

  function hasActiveChild(childrenNodes: any) {
    const activeChildren = childrenNodes.find((ch: any) => ch.uri === pathname)
    return activeChildren?.uri === pathname
  }

  useEffect(() => {
    if (!navToggle) {
      toggleNavItem(null)
    }
  }, [navToggle])

  return (
    <li
      className="nav-item-desktop"
      onMouseLeave={() => toggleNavItem(null)}
      onMouseEnter={() => toggleNavItem(index)}
    >
      {item.children.length === 0 ? (
        <Link
          to={item.path}
          getProps={({
            isPartiallyCurrent,
          }: {
            isPartiallyCurrent: boolean
          }) => {
            return isPartiallyCurrent
              ? { className: `nav-item-desktop__link active` }
              : { className: `nav-item-desktop__link` }
          }}
        >
          <CircleArrow />
          {item.title}
        </Link>
      ) : (
        <span
          className={
            hasActiveChild(item.children) || itemId === index
              ? "nav-item-desktop__link active"
              : "nav-item-desktop__link"
          }
        >
          <CircleArrow />
          {item.title}
          <div className="nav-item-desktop__link__description">
            <DropdownIcon />
          </div>
        </span>
      )}
      <span className="nav-item-desktop__dropdown-hook">
        {itemId === index && item.children.length >= 1 && (
          <ul className="nav-item-desktop__dropdown__body">
            {item.children.map((link: any, index: number) => (
              <li
                key={index}
                className="nav-item-desktop__dropdown__body__item"
              >
                <Link
                  to={link.uri}
                  getProps={({
                    isPartiallyCurrent,
                  }: {
                    isPartiallyCurrent: boolean
                  }) => {
                    return isPartiallyCurrent
                      ? {
                          className: `nav-item-desktop__dropdown__body__item__link active`,
                        }
                      : {
                          className: `nav-item-desktop__dropdown__body__item__link`,
                        }
                  }}
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </span>
    </li>
  )
}

export default memo(NavItemDesktop)
