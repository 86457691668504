import React from "react"
import "/src/scss/index.scss"
// import { pageTransitionDelay } from "./src/utils/constants"
import BodyLayout from "./src/layouts/body-layout"
import CookieBanner from "./src/components/shared/cookie-banner"
import { LanguageProvider } from "./src/context/language-context"
import Popup from "./src/components/home/popup"

// export const shouldUpdateScroll = () => {
//   window.setTimeout(() => window.scrollTo(0, 0), pageTransitionDelay)
//   return false
// }

export const wrapPageElement = ({ element, props }) => {
  return (
    <LanguageProvider>
      <BodyLayout {...props}>
        <Popup />
        <CookieBanner />
        {element}
      </BodyLayout>
    </LanguageProvider>
  )
}
