import { isBrowser, isDocument } from "./constants"
export type Page = {
  id?: string
  uri: string
  template: { templateName: string }
  locale: { locale: string }
}

export function findPageByTemplate(
  template: string,
  pages: Page[],
  locale: string
) {
  return pages
    .filter((p: Page) => p.template.templateName === template)
    .find((p: Page) => p.locale.locale === locale)
}

export function scrollToTop() {
  if (isBrowser()) return window.scrollTo({ top: 0, behavior: "smooth" })
}

export function scrollToggler(condition: boolean) {
  return condition
    ? (document.documentElement.style.overflow = "hidden")
    : (document.documentElement.style.overflow = "auto")
}

export function nestedArray(array: any[]) {
  return array.reduce((a: any, c: any, i: any) => {
    return i % 2 === 0 ? a?.concat([array?.slice(i, i + 2)]) : a
  }, [])
}
