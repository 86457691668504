import React, {memo} from "react";
import './transition.scss';
import {TransitionProps} from "../../types/components";
import {
    pageTransitionDelay
} from "../../utils/constants";
import {
    TransitionGroup,
    CSSTransition as StyleTransition
} from 'react-transition-group';

export const PageTransition: React.FC<TransitionProps> = ({children, state}) => {
    return (
        <TransitionGroup>
            <StyleTransition
                key={state}
                classNames="page"
                timeout={pageTransitionDelay}
            >
                {children}
            </StyleTransition>
        </TransitionGroup>
    );
};
memo(PageTransition);

export const BoxTransition: React.FC<TransitionProps> = ({children, state}) => {
    return (
        <StyleTransition
            in={state}
            classNames="component"
            timeout={pageTransitionDelay}
        >
            {children}
        </StyleTransition>
    );
};
memo(BoxTransition);