import React, { useEffect, useMemo, useState } from "react"
import "./cookie-banner.scss"
import { useCookies } from "../../hooks/use-cookies"
import { useLanguage } from "../../hooks/use-language"
import { graphql, useStaticQuery } from "gatsby"

type BannerProps = {
  locale: {
    locale: string
  }
  popupOption: {
    acceptButton: string
    contentCookie: string
    essentialButton: string
    fieldGroupName: string
  }
}

const CookieBanner: React.FC = () => {
  const [banner, setBanner] = useState<boolean>(false)
  const { setCookie, getCookie } = useCookies()
  const { language } = useLanguage()
  const { POPUP } = useStaticQuery(graphql`
    query {
      POPUP: allWpPopup {
        nodes {
          locale {
            locale
          }
          popupOption {
            acceptButton
            contentCookie
            essentialButton
            fieldGroupName
          }
        }
      }
    }
  `)

  const content = useMemo(() => {
    return POPUP.nodes.find((i: BannerProps) => i.locale.locale === language)
  }, [language, JSON.stringify(POPUP)])

  function setCookies() {
    setCookie("gatsby-gdpr-google-analytics", "true", 365)
    setCookie("gatsby-gdpr-google-tagmanager", "true", 365)
    setBanner(false)
  }

  useEffect(() => {
    if (
      typeof getCookie("gatsby-gdpr-google-analytics") === "string" ||
      typeof getCookie("gatsby-gdpr-google-tagmanager") === "string"
    ) {
      setBanner(false)
    } else {
      setBanner(true)
    }
  }, [])

  if (!banner) return <></>

  return (
    <div className="cookie-banner">
      <div
        className="cookie-banner__description"
        dangerouslySetInnerHTML={{
          __html: content?.popupOption?.contentCookie,
        }}
      />
      <div className="cookie-banner__actions">
        <button onClick={setCookies} className="button--outlined">
          {content?.popupOption?.essentialButton}
        </button>
        <button onClick={setCookies} className="button--branded">
          {content?.popupOption?.acceptButton}
        </button>
      </div>
    </div>
  )
}

export default CookieBanner
