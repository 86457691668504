import React from "react"
import "./disclaimer.scss"
import { Facebook, Instagram, LinkedIn } from "../elements/svg-elements"
import { useLanguage } from "../../hooks/use-language"
import { graphql, useStaticQuery } from "gatsby"

type Props = {
  className: "disclaimer--header" | "disclaimer--footer"
}

const Disclaimer: React.FC<Props> = ({ className }) => {
  const { translate } = useLanguage()
  const { SOCIAL_LINKS } = useStaticQuery(graphql`
    query {
      SOCIAL_LINKS: allWp {
        nodes {
          footerOptionsSettings {
            footerOptions {
              socialMedia {
                linkedin
              }
            }
          }
        }
      }
    }
  `)
  const socialLinks =
    SOCIAL_LINKS?.nodes[0]?.footerOptionsSettings?.footerOptions?.socialMedia

  return (
    <div className={`disclaimer ${className}`}>
      {new Date().getFullYear()} © Como Consult GmbH.{" "}
      {translate("allRightsReserved")}
      <div className="disclaimer__social-icons">
        {/* <a target="_blank" href={socialLinks?.facebook}>
          <Facebook />
        </a>
        <a target="_blank" href={socialLinks?.instagram}>
          <Instagram />
        </a>*/}
        <a target="_blank" href={socialLinks?.linkedin}>
          <LinkedIn />
        </a>
      </div>
    </div>
  )
}

export default Disclaimer
