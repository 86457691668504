import {
  Children,
  ImgProps,
  FetchedSlideProps,
  ProgressiveBarProps,
  MapMarkerProps,
  CountUpElementProps,
  AuthorProps,
  Parent,
  TeamMemberMainAreaProps,
  TeamMemberMainContentProps,
  FetchedThemePointProps,
  BoxListProps,
  ArticleItemProps,
  AuthorSelectProps,
  DynamicContentCasesProps,
} from "./shared-singulars"

/*
    Page types
*/
export type TransitionProps = {
  children: Children
  state: any
}

export type SeoProps = {
  utils: {
    title: string
    lang: any
    meta: (
      | {
          name: string
          content: string
        }
      | {
          property: string
          content: string
        }
    )[]
  }
}

export type HeaderProps = {
  isNotFound?: boolean
  responsibilitiesPage: boolean
  phylosophyPage: boolean
}

export enum HeaderActionType {
  SET_Y_POSITION = "setYPosition",
  SET_SCROLL = "setScroll",
  SET_NAVBAR = "setNavbar",
  SET_NAV_ITEM = "setNavItem",
  SET_MULTIPLE = "setMultiple",
  SET_NAVLINKS = "setNavLinks",
}

export type NavLinkProps = {
  title: string
  path: string
  children: Array<{ [key: string]: any }>
}

export type HeaderStateProps = {
  y: any
  navbar: boolean
  scroll: boolean
  itemId: null | number
  navLinks: string[] | []
}

export type HeaderActionProps = {
  type: HeaderActionType
  payload: any
}

export type NavItemProps = {
  navToggle: boolean
  linksArray?: string[]
  itemId: number | null
  index: number
  toggleNavItem: (index: number | null) => void
  item: NavLinkProps
}

export type DropdownProps = {
  children: Children
  state: any
}

export type ImageProps = {
  src: string
  className?: string
  alt?: string
}

export type PageHeaderProps = {
  children: Children
}

export interface CountUpNodeProps {
  index?: number
  icon: ImgProps
  description: string
  titleString?: string | JSX.Element
  title: number | string | JSX.Element | null
}

export type AnimatedStatisticsProps = {
  elements: CountUpElementProps[]
}

export type GridContainerProps = {
  children: Children
  parent?: string
}

export type GridItemProps = {
  children: Children
  featuredImage: {
    node: ImgProps
  }
  index?: number
  uri: string
  id?: string
  className?: string
  tags?: string[]
}

export type SliderProps = {
  parent: Parent
  children: Children
  slides: FetchedSlideProps[]
}

export type SliderItemProps = {
  parent: Parent
  index?: number
  uri: string
  imgProps: ImgProps
  authors?: AuthorProps[]
  title: string
  memberTag?: string
  children?: any
}

export type LocationsSectionProps = {
  firstTitle: string
  secondTitle: string
  image: ImgProps
  graphBars: ProgressiveBarProps[]
}

export type PageLinkProps = {
  children?: any
  templateName: string
  delay?: number | string
  className?: string
}

export type MapLocationProps = {
  address: string
  locationImage: ImgProps
  name: string
  index?: number
}

export type PhilosophyHeaderProps = {
  title: string
  views: {
    title: string
    content: string
  }[]
}
export type PhilosophyHeaderMobileProps = {
  views: {
    imageMobile?: ImgProps
    title: string
    content: string
  }
}
export type PPIContentProps = {
  title: string
  leftContent: string
  rightContent: string
}

export type MapStateProps = {
  mapContainerClassName: string
  zoom: number
  center: {
    lat: number
    lng: number
  }
  map: { [key: string]: any } | null
  infoWindow: number | null
}

export enum MapActionType {
  SET_MAP_CENTER = "setMapCenter",
  SET_MAP_SETTINGS = "setMapSettings",
  SET_INFO_WINDOW = "setInfoWindow",
  SET_MULTIPLE = "setMultiple",
}

export type MapActionProps = {
  type: MapActionType
  payload: any
}

export type GoogleMapProps = {
  initialCenter: {
    lat: number
    lng: number
  }
  markers: MapMarkerProps[]
}

export type ContactHeaderProps = {
  pageTitle: string
  email: string
  subtitle: string
  title: string
}

export type PhilosophySectionProps = {
  aboutUs: string
  consultingPhilosophy: {
    title: string
    url: string
  }
  ourResponsibility: string
  aboutUsStaticPost: {
    content: string
    title: string
  }
}

export type ResponsibilityHeaderProps = {
  children: any
  banner: ImgProps
}

export type ResponsibilityBodyProps = {
  children: Children
}

export type TeamGridProps = {
  children: Children
  sectionClass: string
}

export type TeamMemberBannerProps = {
  title: string
  position: string
  phoneNumber: string
  email: string
  goBack: string
  banner: ImgProps
}

export type TeamMemberContentProps = {
  mainAreas: TeamMemberMainAreaProps
  mainContent: TeamMemberMainContentProps
  themePoints: FetchedThemePointProps[]
  boxList: BoxListProps
  work: ArticleItemProps[]
}

export type ThemePointProps = {
  index: number
  content: string
  number: number
}

export type TeamPostItemProps = {
  imgProps: ImgProps
  title: string
  index: number
  uri: string
  contentType?: string
}

export type ArticleBodyProps = {
  featuredImage: ImgProps
  title: string
  tagsTitle: string
  tags: string
  content: string
  theAuthor: string
  author: AuthorSelectProps[]
}

export type AuthorCardProps = {
  theAuthor: string
  author: AuthorSelectProps[]
}

export type CaseBannerProps = {
  nextBtn?: boolean
  uri: string
  tagsTitle: string
  tags: string
  title: string
  subtitle: string
  caseBannerImage: ImgProps
}

export type CaseContentProps = {
  imageOutsideAndText?: {
    order: string
    text: string
    image: ImgProps
  }
  dynamicContent?: {
    order: string
    dynamicContentCases: DynamicContentCasesProps[]
  }
}
export type DropDownProps = {
  mainAreas: TeamMemberMainAreaProps
}
