import React, { useCallback } from "react"
import "../scss/main.scss"
import Header from "../components/partials/header"
import Footer from "../components/partials/footer"
import { PageTransition } from "../components/shared/transition"
import { BodyLayoutProps } from "../types/pages"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@gatsbyjs/reach-router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

type PageProps = {
  uri: string
  template: {
    templateName: string
  }
}

const BodyLayout: React.FC<BodyLayoutProps> = ({ children }) => {
  const location = useLocation()
  initializeAndTrack(location)
  const { PAGES } = useStaticQuery(graphql`
    query {
      PAGES: allWpPage {
        nodes {
          uri
          template {
            templateName
          }
        }
      }
    }
  `)

  const isTargetPage = useCallback(
    (templateName: string) => {
      const targetPage = PAGES?.nodes?.find(
        (p: PageProps) => p?.uri === location.pathname
      )
      return targetPage?.template?.templateName === templateName
    },
    [location.pathname, JSON.stringify(PAGES)]
  )

  return (
    <>
      <Header
        responsibilitiesPage={isTargetPage("Responsibilities")}
        phylosophyPage={isTargetPage("Philosophy")}
      />
      <PageTransition state={location.pathname}>
        <main
          className={isTargetPage("Responsibilities") ? "modifier" : ""}
          id={
            location.pathname === "/not-found" ||
            location.pathname === "/not-found/"
              ? "not-found-page"
              : ""
          }
        >
          {children}
        </main>
      </PageTransition>
      <Footer />
    </>
  )
}

export default BodyLayout
