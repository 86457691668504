import React, { createContext, useEffect, useState } from "react"
import { useLocation } from "@gatsbyjs/reach-router"
import { useStaticQuery, graphql } from "gatsby"
import { translations } from "../utils/translations"

/*
    Types
*/
export type LanguageContextProps = {
  language: string
  setLanguage: (lang: string) => void
  translate: (word: string) => any
}

interface LanguageProviderProps {
  children: any
}

/*
    Context
*/
const LanguageContext = createContext<LanguageContextProps>({
  language: "",
  setLanguage() {},
  translate() {},
})

/*
    Consumers
*/
const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const { pathname } = useLocation()
  const [state, setState] = useState({
    language: "",
  })

  const { PAGES, TEAM_PAGES, CASES, ARTICLES } = useStaticQuery(graphql`
    query {
      PAGES: allWpPage {
        nodes {
          uri
          locale {
            locale
          }
        }
      }
      TEAM_PAGES: allWpTeam {
        nodes {
          uri
          locale {
            locale
          }
        }
      }
      CASES: allWpCase {
        nodes {
          uri
          locale {
            locale
          }
        }
      }
      ARTICLES: allWpArticle {
        nodes {
          uri
          locale {
            locale
          }
        }
      }
    }
  `)

  function setCurrentLocale() {
    const allPages = [
      ...TEAM_PAGES.nodes,
      ...ARTICLES.nodes,
      ...PAGES.nodes,
      ...CASES.nodes,
    ]
    let currentLocale = allPages?.find(
      (p: { uri: string; locale: {} }) => p.uri === pathname
    )?.locale?.locale
    setState({
      ...state,
      language: currentLocale || "",
    })
  }

  function setLanguage(lang: string) {
    setState({
      ...state,
      language: lang,
    })
  }

  function translate(word: string) {
    if (state.language !== "") {
      if (translations[state.language][word]) {
        return translations[state.language][word]
      }
      return ""
    }
  }

  useEffect(() => {
    setCurrentLocale()
  }, [pathname, PAGES])

  let { language } = state

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        translate,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
