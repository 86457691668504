import React from "react"
import { useStaticQuery, Link as GatsbyLink, graphql } from "gatsby"
import { PageLinkProps } from "../../types/components"
import { useLanguage } from "../../hooks/use-language"
import { findPageByTemplate } from "../../utils/helpers"

export const PageLink: React.FC<PageLinkProps> = ({
  children,
  templateName,
  delay,
  className,
}) => {
  const { language } = useLanguage()
  const { PAGES } = useStaticQuery(graphql`
    query {
      PAGES: allWpPage {
        nodes {
          uri
          template {
            templateName
          }
          locale {
            locale
          }
        }
      }
    }
  `)

  const path = findPageByTemplate(templateName, PAGES.nodes, language)

  if (templateName === "") {
    return (
      <GatsbyLink to={""} className={className}>
        {children}
      </GatsbyLink>
    )
  } else {
    return (
      <GatsbyLink
        className={className}
        to={path ? path.uri : ""}
        getProps={({ isPartiallyCurrent }: any) => {
          return isPartiallyCurrent
            ? { className: `${className} active` }
            : { className: `${className}` }
        }}
        data-aos={delay ? "fade-in" : null}
        data-aos-delay={delay}
        data-aos-easing={delay ? "ease-out" : null}
      >
        {children}
      </GatsbyLink>
    )
  }
}

export default PageLink
