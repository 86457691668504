interface Translations  {
    [key: string]: any;
}

export const translations = {
    de_DE: {
        allRightsReserved: 'Alle Rechte vorbehalten.',
        notFoundPage: 'Page cannot be found',
        backToHome: 'Back to Home Page',
        acceptAll: 'Alle akzeptieren',
        essentialsOnly: 'Essentials Only',
        cookiesDisclaimer: 'Wie alle anderen verwenden wir auch Cookies. Sie können mehr über ihre Verwendung in unserem',
        privacyPolicy: 'Datenschutzrichtlinie.',
    },
    en_US: {
        allRightsReserved: 'All rights reserved.',
        notFoundPage: 'Page cannot be found',
        backToHome: 'Back to Home Page',
        acceptAll: 'Accept All',
        essentialsOnly: 'Essentials Only',
        cookiesDisclaimer: 'Like everyone else, we use cookies. You can read more about their use in our',
        privacyPolicy: 'Política de privacidad.',
    },
    fr_FR: {
        allRightsReserved: 'Tous les droits sont réservés.',
        notFoundPage: 'Page cannot be found',
        backToHome: 'Back to Home Page',
        acceptAll: 'Tout accepter',
        essentialsOnly: "N'acceptez que les cookies essentiels",
        cookiesDisclaimer: 'Comme tout le monde, nous utilisons des cookies. Vous pouvez en savoir plus sur leur utilisation dans notre',
        privacyPolicy: 'Privacy policy.',
    },
    es_ES: {
        allRightsReserved: 'Todos los derechos reservados.',
        notFoundPage: 'Page cannot be found',
        backToHome: 'Back to Home Page',
        acceptAll: 'Aceptar todo',
        essentialsOnly: 'Aceptar solo lo esencial',
        cookiesDisclaimer: 'Como todos los demás, utilizamos cookies. Puedes leer más sobre su uso en nuestro',
        privacyPolicy: 'Política de privacidad.',
    }
} as Translations;