import React, {
  useMemo,
  useEffect,
  useCallback,
  useReducer,
  useRef,
} from "react"
import "./header.scss"
import { HeaderProps, HeaderActionType } from "../../types/components"
import { Logo } from "../elements/svg-elements"
import Locales from "./locales"
import { useStaticQuery, graphql } from "gatsby"
import { PageLink } from "../shared/page-link"
import { useLanguage } from "../../hooks/use-language"
import { useLocation } from "@gatsbyjs/reach-router"
import { isBrowser } from "../../utils/constants"
import NavItemDesktop from "./nav-item-desktop"
import { scrollToggler } from "../../utils/helpers"
import Disclaimer from "./disclaimer"
import { headerReducer } from "../../utils/reducers"
import NavItemMobile from "./nav-item-mobile"

type Page = {
  title: string
  uri: string
  id: string
  parentId: string
  menuOrder: string
  locale: {
    locale: string
  }
  template: {
    templateName: string
  }
}

const Header: React.FC<HeaderProps> = ({
  responsibilitiesPage,
  phylosophyPage,
}) => {
  const { pathname } = useLocation()
  const { language } = useLanguage()
  const [state, dispatch] = useReducer(headerReducer, {
    y: () => isBrowser() && window.scrollY,
    scroll: true,
    navbar: false,
    itemId: null,
    navLinks: [],
  })
  const navRef = useRef<any>()

  const { PAGES } = useStaticQuery(graphql`
    query {
      PAGES: allWpPage {
        nodes {
          title
          uri
          id
          parentId
          menuOrder
          locale {
            locale
          }
          template {
            templateName
          }
        }
      }
    }
  `)

  const navItems = useMemo(() => {
    let filteredPages = PAGES.nodes.filter(
      (p: Page) =>
        p.locale.locale === language &&
        !["Home", "Privacy-policy", "Impressum"].includes(
          p.template.templateName
        )
    )

    dispatch({
      type: HeaderActionType.SET_NAVLINKS,
      payload: filteredPages.map((p: Page) => p.uri),
    })

    return filteredPages
      .filter((p: Page) => !p.parentId)
      .map((p: Page) => {
        return {
          menuOrder: p.menuOrder,
          path: p.uri,
          title: p.title,
          children: filteredPages
            .filter((ch: Page) => ch.parentId === p.id)
            .sort((a: Page, b: Page) =>
              a.menuOrder > b.menuOrder ? 1 : b.menuOrder > a.menuOrder ? -1 : 0
            ),
        }
      })
      .sort((a: Page, b: Page) =>
        a.menuOrder > b.menuOrder ? 1 : b.menuOrder > a.menuOrder ? -1 : 0
      )
  }, [language])

  const handleNavigation = useCallback(
    (e: any) => {
      const headerContainer = navRef.current
      const window = e.currentTarget
      if (
        window.scrollY <= 5 ||
        state.y > window.scrollY ||
        (phylosophyPage &&
          window.scrollY <= Number(headerContainer.offsetWidth + 125))
      ) {
        dispatch({ type: HeaderActionType.SET_SCROLL, payload: true })
      } else {
        dispatch({ type: HeaderActionType.SET_SCROLL, payload: false })
      }
      dispatch({
        type: HeaderActionType.SET_Y_POSITION,
        payload: window.scrollY,
      })
    },
    [state.y]
  )

  useEffect(() => {
    dispatch({ type: HeaderActionType.SET_Y_POSITION, payload: window.scrollY })
    window.addEventListener("scroll", handleNavigation)

    return () => {
      window.removeEventListener("scroll", handleNavigation)
    }
  }, [handleNavigation])

  useEffect(() => {
    scrollToggler(state.navbar)
  }, [state.navbar])

  useEffect(() => {
    dispatch({ type: HeaderActionType.SET_NAVBAR, payload: false })
  }, [pathname])

  function toggleNavbar(state: boolean) {
    return dispatch({ type: HeaderActionType.SET_NAVBAR, payload: state })
  }

  function toggleNavItem(index: number | null) {
    if (index !== state.itemId) {
      return dispatch({ type: HeaderActionType.SET_NAV_ITEM, payload: index })
    } else {
      return dispatch({ type: HeaderActionType.SET_NAV_ITEM, payload: null })
    }
  }

  return (
    <header
      className={
        pathname === "/not-found" || location.pathname === "/not-found/"
          ? "not-found-page-nav"
          : "header"
      }
      ref={navRef}
      id="header"
    >
      <div className="header__wrapper container--xl">
        <PageLink templateName="Home" className="header__logo">
          <Logo
            style={{ color: "#A09CA2" }}
            onClick={() => toggleNavbar(false)}
          />
        </PageLink>
        <button
          onClick={() => toggleNavbar(!state.navbar)}
          className={
            state.navbar ? "header__hamburger active" : "header__hamburger"
          }
        >
          <span />
        </button>
        <ul className={state.navbar ? "header__items shown" : "header__items"}>
          <div className="header__items__wrapper">
            {navItems &&
              navRef?.current?.offsetWidth > 1024 &&
              navItems.map((item: any, index: number) => (
                <NavItemDesktop
                  key={index}
                  index={index}
                  item={item}
                  navToggle={state.navbar}
                  toggleNavItem={(index: number | null) => toggleNavItem(index)}
                  itemId={state.itemId}
                />
              ))}
            {navItems &&
              navRef?.current?.offsetWidth <= 1024 &&
              navItems.map((item: any, index: number) => (
                <NavItemMobile
                  key={index}
                  index={index}
                  linksArray={state.navLinks}
                  item={item}
                  navToggle={state.navbar}
                  toggleNavItem={(index: number | null) => toggleNavItem(index)}
                  itemId={state.itemId}
                />
              ))}
            <Locales
              toggleNavItem={(index: number | null) => toggleNavItem(index)}
              itemId={state.itemId}
              linksArray={state.navLinks}
            />
          </div>
          <div className="header__items__disclaimer">
            <Disclaimer className="disclaimer--header" />
          </div>
        </ul>
      </div>
    </header>
  )
}

export default Header
